<template>
  <div>
    <c-tab
      type="horizon"
      :dense="true"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :height="tabHeight"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="popupParam"
          :check.sync="check"
          :company.sync="company"
          :isOld="isOld"
          :disabled="disabled"
          :contentHeight.sync="contentHeight"
          :imprTabInfo.sync="imprTabInfo"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'fireFightingCheckDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingCheckId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'info',
      check: {
        sopFireFightingCheckId: '',  // 소방설비 점검 일련번호
        plantCd: '',  // 사업장
        checkName: '',  // 점검명
        sopFireFightingCheckStepCd: '',  // 점검진행상태
        checkDate: '',  // 점검일
        checkerId: '',  // 수행자
        reportDate: '',  // 보고일시
        sopReportMethodCd: '',  // 보고방법
        receivedReportId: '',  // 보고받은 사람
        sopActionMethodCd: '',  // 조치방법
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        targets: [],
        deleteTargets: [],
        results: [],
        deleteResults: [],
        imprs: [],
      },
      company: {
        mdmCompanyId: '',  // 회사 일련번호
        companyName: '',  // 회사명(상호)
        bizNo: '',  // 사업자번호
        managerName: '',  // 대표자
        addr: '',  // 소재지
        underFloor: '',  // 지하층
        upFloor: '',  // 지상층
        totalFloorArea: '',  // 연면적
        floorArea: '',  // 바닥면적
        buildCount: '',  // 동수
        fireMngGradeCd: null,  // 소방안전관리대상물 등급
      },
      editable: '',
      detailUrl: '',
      detailCompanyUrl: '',
      listItemUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.sopFireFightingCheckId)
    },
    disabled() {
      return this.check.sopFireFightingCheckStepCd === 'FFC0000005'
    },
    tabHeight() {
      return String(this.contentHeight - 36);
    },
    tabItems() {
      let _items = [
        { name: 'info', icon: 'info', label: '점검상세', component: () => import(`${'./fireFightingCheckInfo.vue'}`) },
      ]
      if (this.isOld) {
        _items.push({
          name: 'impr', icon: 'construction', label: '개선관리', component: () => import(`${'@/pages/common/ibm/imprTab.vue'}`)
        })
      }
      return _items;
    },
    imprTabInfo() {
      return {
        key: this.popupParam.sopFireFightingCheckId, // 
        ibmTaskTypeCd: 'ITT0000180',
        ibmTaskUnderTypeCd: 'ITTU000205',
        requestContents: this.check.checkName,
        disabled: this.disabled,
        isOld: true,
        isTable: true,
        gridItem: {
          listUrl: '',
          param: null,
          title: '점검항목별 개선목록',
          merge: [
            { index: 0, colName: 'sopFireFightingCheckTargetResultId' },
            { index: 1, colName: 'sopFireFightingCheckTargetResultId' },
            { index: 2, colName: 'sopFireFightingCheckTargetResultId' },
          ],
          data: this.check.imprs,
          columns: [
            {
              name: 'itemName',
              field: 'itemName',
              label: '점검항목',
              align: 'left',
              style: 'width: 400px',
              sortable: false,
            },
            {
              name: 'remarks',
              field: 'remarks',
              label: '확인내용',
              align: 'left',
              style: 'width: 350px',
              sortable: false,
            },
            {
              name: 'sopFireFightingCheckResultName',
              field: 'sopFireFightingCheckResultName',
              label: '확인결과',
              align: 'center',
              style: 'width: 100px',
              sortable: false,
            },
          ],
          research: {},
        },
        gridOther: {
          //항목외 개선 목록
          title: 'LBL0010147',
        },
      }
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.fft.fireFightingCheck.get.url
      this.detailCompanyUrl = selectConfig.mdm.company.get.url;
      this.listItemUrl = selectConfig.fft.base.item.list.url
      // code setting
      // list setting
      this.getDetail();
      this.getDetailCompany();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopFireFightingCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.check, _result.data)
        },);
      } else {
        this.$set(this.check, 'sopFireFightingCheckStepCd', 'FFC0000001')
        this.$http.url = this.listItemUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.$store.getters.user.plantCd,
          useFlag: 'Y'
        }
        this.$http.request((_result) => {
          if (_result.data && _result.data.length > 0) {
            this.$set(this.check, 'results', this.$_.map(_result.data, item => {
              return this.$_.extend(item, {
                sopFireFightingCheckResultCd: 'FFCR000001',
                remarks: '',
                editFlag: 'C',
                regUserId: this.$store.getters.user.userId
              })
            }))
          }
        },);
      }
    },
    getDetailCompany() {
      this.$http.url = this.$format(this.detailCompanyUrl, 'C000000001');
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.company, _result.data);
      },);
    },
    closePopup() {
      this.$emit('closePopup')
    }
  }
};
</script>